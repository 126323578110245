/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import Page from '~layout/Page';
import SEO from '~layout/SearchEngineOptimization';
import SweetAlert from '~components/alerts/SweetAlert';
import SubscribeContainer from '~src/containers/join-premium/SubscribeContainer';
import unfurl from '~prismic/unfurl/subscribe-page';

const seo = {
  seo_title: 'Subscribe To Aleph Beta | Explore The Jewish Bible',
  seo_description:
    "Experience Torah like never before. Aleph Beta is committed to the relevance of Jewish learning by helping our students answer life's biggest questions through Torah study.",
  seo_keywords: 'jewish bible, torah bible, jewish bible torah, hebrew bible torah'
};

const SubscribePage = ({ location, data }) => {
  const { offer } = unfurl(data);
  return (
    <Page>
      <SEO {...seo} />
      <SweetAlert state={location.state} />
      <SubscribeContainer offer={offer} />
    </Page>
  );
};

export default withPrismicPreview(SubscribePage);

export const query = graphql`
  {
    prismicSubscribePage {
      _previewable
      data {
        show_offer
        offer_template
        offer_title
        offer_description {
          html
        }
        offer_background_color
        offer_text_color
        offer_image {
          alt
          url
        }
      }
    }
  }
`;
